import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { apiUrl, LANGUAGE, PRODUCT_VIEW } from './constants';
import {
  Banner,
  Language,
  Menu,
  MenuContent,
  MenuSection,
  MenuSetting,
  NavigationButton,
  ProductGroup,
  ProductListView,
  THEMES,
} from '../../types';
import { fetchData, isValidLanguage } from '@utils/functions';
import { ProductWithDaypart } from '@components/ProductList/product-list';

type State = {
  language: Language;
  productListView: ProductListView;
  menus: Menu[];
  banners: Banner[];
  menuContent: MenuContent;
  menuSections: MenuSection[];
  products: ProductGroup[];
  settings: MenuSetting[];
  theme: string;
  navigationButtons: NavigationButton[];
};

type StoreProps = State & {
  setLanguage(lang: string): void;
  setProductListView(view: ProductListView): void;
  loadMenuContent(menuId: string): Promise<void>;
  getProductById(productId: string): ProductWithDaypart | undefined;
  sendReview(rate: number, comment?: string, email?: string): Promise<void>;
  fetchSettings(): Promise<void>;
};

const initialState: StoreProps = {
  language: LANGUAGE.EN,
  productListView: PRODUCT_VIEW.GRID,
  menus: [],
  menuContent: {} as MenuContent,
  menuSections: [],
  products: [],
  banners: [],
  settings: [],
  theme: THEMES.NONE,
  navigationButtons: [],
  setLanguage: () => {},
  setProductListView: () => {},
  loadMenuContent: async () => {},
  getProductById: () => undefined,
  sendReview: async () => {},
  fetchSettings: async () => {},
};

export const StoreContext = createContext<StoreProps>(initialState);

export const useStore = () => useContext(StoreContext);

enum ACTION {
  // Sets the app language
  SET_LANGUAGE,
  // Sets the app language
  SET_PRODUCT_LIST_VIEW,
  // loads the list of menus to be used in the Home page
  SET_MENU_LIST_DATA,
  // loads the content of the selected menu (sections and products)
  SET_MENU_CONTENT_DATA,
  // Loads all sections of the menu
  SET_MENU_SECTIONS,
  // Loads all products
  SET_PRODUCTS,
  // Loads all banners
  SET_BANNERS,
  // Loads menu settings
  SET_SETTINGS,
  // Load navigation buttons
  SET_NAVIGATION_BUTTONS,
}

type Action =
  | { type: ACTION.SET_LANGUAGE; payload: Language }
  | { type: ACTION.SET_PRODUCT_LIST_VIEW; payload: ProductListView }
  | { type: ACTION.SET_MENU_LIST_DATA; payload: Menu[] }
  | { type: ACTION.SET_MENU_CONTENT_DATA; payload: MenuContent }
  | { type: ACTION.SET_MENU_SECTIONS; payload: MenuSection[] }
  | { type: ACTION.SET_PRODUCTS; payload: ProductGroup[] }
  | { type: ACTION.SET_BANNERS; payload: Banner[] }
  | { type: ACTION.SET_SETTINGS; payload: MenuSetting[] }
  | { type: ACTION.SET_NAVIGATION_BUTTONS; payload: NavigationButton[] };

const storeReducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_LANGUAGE:
      return { ...state, language: payload };
    case ACTION.SET_PRODUCT_LIST_VIEW:
      return { ...state, productListView: payload };
    case ACTION.SET_MENU_LIST_DATA:
      return { ...state, menus: payload };
    case ACTION.SET_MENU_CONTENT_DATA:
      return { ...state, menuContent: payload };
    case ACTION.SET_MENU_SECTIONS:
      return { ...state, menuSections: payload };
    case ACTION.SET_PRODUCTS:
      return { ...state, products: payload };
    case ACTION.SET_BANNERS:
      return { ...state, banners: payload };
    case ACTION.SET_SETTINGS:
      return { ...state, settings: payload };
    case ACTION.SET_NAVIGATION_BUTTONS:
      return { ...state, navigationButtons: payload };
    default:
      return state;
  }
};

export const StoreProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const setLanguage = (lang: Language) => {
    localStorage.setItem('LANGUAGE', lang);
    dispatch({ type: ACTION.SET_LANGUAGE, payload: lang });
  };

  const setProductListView = (view: ProductListView) => {
    localStorage.setItem('PRODUCT_LIST_VIEW', view);
    dispatch({ type: ACTION.SET_PRODUCT_LIST_VIEW, payload: view });
  };

  const loadMenusList = useCallback(async (): Promise<void> => {
    const menuList = await fetchData<Menu[]>(`${apiUrl}/online-menu`);
    dispatch({ type: ACTION.SET_MENU_LIST_DATA, payload: menuList });
  }, []);

  const loadMenuContent = useCallback(async (menuId: string): Promise<void> => {
    const menuContent = await fetchData<MenuContent>(`${apiUrl}/online-menu/${menuId}`);
    dispatch({ type: ACTION.SET_MENU_CONTENT_DATA, payload: menuContent });
  }, []);

  const fetchSettings = useCallback(async (): Promise<void> => {
    const settings = await fetchData<MenuSetting[]>(`${apiUrl}/menu-settings`);
    dispatch({ type: ACTION.SET_SETTINGS, payload: settings });
  }, []);

  const loadLanguageFromLocalStorage = useCallback(() => {
    let lang = localStorage.getItem('LANGUAGE');

    if (!lang) {
      lang = navigator.language.split(/[-_]/)[0].toUpperCase();
    }

    if (!lang || !isValidLanguage(lang)) {
      lang = LANGUAGE.EN;
    }

    setLanguage(lang as Language);
  }, []);

  const loadProductListViewFromLocalStorage = useCallback(() => {
    let view = localStorage.getItem('PRODUCT_LIST_VIEW');

    if (view) {
      setProductListView(view as ProductListView);
    }
  }, []);

  const loadBanners = useCallback(async () => {
    const banners = await fetchData<Banner[]>(`${apiUrl}/online-menu/banners`);
    dispatch({ type: ACTION.SET_BANNERS, payload: banners });
  }, []);

  const loadNavigationButtons = useCallback(async () => {
    const navButtons = await fetchData<NavigationButton[]>(
      `${apiUrl}/online-menu/navigation-buttons`
    );
    dispatch({ type: ACTION.SET_NAVIGATION_BUTTONS, payload: navButtons });
  }, []);

  const getProductById = (productId: string): ProductWithDaypart | undefined => {
    for (const section of state?.menuContent?.sections || []) {
      for (const productCategory of section?.productCategory || []) {
        for (const product of productCategory?.products || [])
          if (product.id === productId) {
            return {
              ...product,
              daypart: section.daypart,
              inCurrentDaypart: section.inCurrentDaypart,
            };
          }
      }
    }
    return undefined;
  };

  const sendReview = async (rate: number, comment?: string, email?: string): Promise<void> => {
    try {
      await fetchData(`${apiUrl}/review`, {
        method: 'POST',
        body: JSON.stringify({
          rate,
          comment,
          email,
        }),
      });
    } catch (error) {
      console.log('🚀 ~ file: store.tsx:177 ~ sendReview ~ error:', error);
    }
  };

  const theme = useMemo(
    () => state.settings.find(({ key }) => key === 'THEME')?.value || THEMES.NONE,
    [state.settings]
  );

  useEffect(() => {
    fetchSettings();
    loadLanguageFromLocalStorage();
    loadProductListViewFromLocalStorage();
    loadBanners();
    loadMenusList();
    loadNavigationButtons();
  }, [
    fetchSettings,
    loadBanners,
    loadLanguageFromLocalStorage,
    loadMenusList,
    loadNavigationButtons,
    loadProductListViewFromLocalStorage,
  ]);

  const StoreValues: StoreProps = {
    ...state,
    theme,
    setLanguage,
    setProductListView,
    loadMenuContent,
    getProductById,
    sendReview,
    fetchSettings,
  };

  return <StoreContext.Provider value={StoreValues}>{children}</StoreContext.Provider>;
};

export default StoreContext.Consumer;
