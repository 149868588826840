import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { COLOR } from '@utils/colors';

export const NavBarContainer = styled(Box)({
  display: 'flex',
  background: COLOR.backgroundMenu,
  borderTop: '1px solid white',
});

export const ButtonContainer = styled(Box)(({ selected }: { selected: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingTop: '10px',
  paddingBottom: '10px',
  alignItems: 'center',
  color: selected ? COLOR.ZerenoLight : COLOR.Zereno,
}));

export const ButtonLabel = styled(Typography)(({ selected }: { selected: boolean }) => ({
  color: selected ? COLOR.ZerenoLight : COLOR.Zereno,
  fontWeight: selected ? 400 : 700,
  fontSize: '11px',
  marginTop: '2px',
}));
