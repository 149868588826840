import { SvgIcon } from '@mui/material';

const ZerenoLogoHalloween = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 123.54367 57.925625">
    <g transform="matrix(0.26458333,0,0,0.26458334,368.70997,-99.6134)">
      <path
        d="m -1165.6636,380.25619 c -11.8407,10.24533 -12.8604,27.428 -12.8604,27.428 l 12.2484,10.12533 16.3293,-7.59467 c 0,0 -14.4573,-19.94933 -5.1026,-27.48133 4.6693,-3.76 10.828,-0.824 10.828,-0.824 l 2.5826,-3.244 c 0,0 -15.4573,-5.82267 -24.0253,1.59067"
        style={{
          fill: '#4cbd38',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.133333,
        }}
      />
      <path
        style={{
          fill: '#ee4a1d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.133333,
        }}
        d="m -1207.6494,402.38671 c -17.5505,0 -33.3739,27.15122 -35.3438,60.64453 -1.9692,33.49329 10.66,60.64648 28.211,60.64648 12.3695,0 23.8748,-13.49743 30.2832,-33.1875 h -18.2539 v -6.67773 h 0.023 l 17.041,-20.78125 h -17.0644 v -6.67188 c 8.2773,0 16.3005,-2.16981 23.3515,-6.13476 -0.278,-5.15783 -0.9157,-10.06768 -1.8672,-14.64649 h -21.4843 v -6.67187 h 19.8144 c -4.7794,-16.00688 -13.7923,-26.51953 -24.7109,-26.51953 z m 27.5215,68.13672 c -4.1939,2.67835 -7.9949,6.01465 -11.2227,9.95507 l -2.7305,3.33399 h 11.5059 c 1.0566,-4.22778 1.8786,-8.68146 2.4473,-13.28906 z"
      />
      <path
        style={{
          fill: '#ee4a1d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.133333,
        }}
        d="m -1129.7236,402.38671 c -10.9179,0 -19.9297,10.51268 -24.709,26.51953 h 13.2558 v 6.67187 h -13.3691 l -2.0371,2.48243 c -1.0145,5.68753 -1.558,11.83817 -1.5489,18.29882 h 16.9551 v 6.67188 h -13.3457 c -1.1403,0 -2.2738,0.056 -3.4023,0.13672 0.4347,7.25016 1.5172,14.20335 3.1269,20.64453 h 13.6211 v 6.67773 h -11.6972 c 6.4081,19.69007 17.9132,33.1875 30.2832,33.1875 17.5506,0 30.1822,-27.15319 28.2129,-60.64648 -1.9707,-33.49331 -17.7951,-60.64453 -35.3457,-60.64453 z"
      />
      <path
        style={{
          fill: '#ef531b',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.133333,
        }}
        d="m -1186.4307,402.38671 c -18.3016,0 -34.8033,27.15122 -36.8574,60.64453 -2.0536,33.49329 11.1183,60.64648 29.4199,60.64648 12.8996,0 24.8973,-13.49742 31.5801,-33.1875 h -40.4648 v -6.67773 h 0.023 l 17.041,-20.78125 h -17.0644 v -6.67188 c 14.256,0 27.7808,-6.39612 36.83,-17.41406 l 2.7305,-3.36719 h -39.5605 v -6.67187 h 42.0898 c -4.984,-16.00668 -14.3811,-26.51953 -25.7676,-26.51953 z m 28.3379,37.51758 -13.4941,16.45507 h 14.7812 c 0.01,-5.76696 -0.4384,-11.2902 -1.2871,-16.45507 z m 1.0762,23.19726 c -13.3481,0.69854 -25.8137,6.97571 -34.334,17.37695 l -2.7305,3.33399 h 33.7989 c 1.6839,-6.46099 2.8148,-13.43664 3.2656,-20.71094 z"
      />
      <path
        style={{
          fill: '#ef531b',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.133333,
        }}
        d="m -1147.2686,402.38671 c -11.3856,0 -20.7815,10.51285 -25.7656,26.51953 h 31.8574 v 6.67187 h -13.3691 l -17.041,20.78125 h 30.4101 v 6.67188 h -13.3457 c -7.6519,0 -15.0719,1.8543 -21.7109,5.25976 0.5855,5.40602 1.5394,10.61425 2.8183,15.52149 h 32.2383 v 6.67773 h -30.2324 c 6.6827,19.6899 18.6801,33.1875 31.5801,33.1875 18.3013,0 31.4726,-27.15319 29.418,-60.64648 -2.0533,-33.49331 -18.5548,-60.64453 -36.8575,-60.64453 z m -27.5097,33.1914 c -0.8828,4.07369 -1.5072,8.40922 -1.8379,12.94922 3.9831,-2.61329 7.6042,-5.82079 10.6933,-9.58203 l 2.7305,-3.36719 z"
      />
      <path
        style={{
          fill: '#f05f19',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
          strokeWidth: 0.133333,
        }}
        d="m -1165.9346,402.38671 c -9.7192,0 -18.2907,10.51267 -23.3828,26.51953 h 46.7656 c -5.0919,-16.00686 -13.6643,-26.51953 -23.3828,-26.51953 z m 24.7578,31.31445 v 1.87695 h -13.3691 l -17.041,20.78125 h 30.4101 v 6.67188 h -13.3457 c -14.2801,0 -27.7791,6.40059 -36.8281,17.44726 l -1.418,1.73047 c 0.083,0.53553 0.162,1.07475 0.252,1.60352 h 51.3398 v 6.67773 h -49.9765 c 4.6734,19.69004 14.2076,33.1875 25.2187,33.1875 15.6214,0 28.2852,-27.15319 28.2852,-60.64648 0,-10.64135 -1.282,-20.63638 -3.5274,-29.33008 z m -49.9765,1.87695 c -1.4536,6.12515 -2.4366,12.84719 -2.8457,19.95313 10.9249,-2.04469 20.9071,-7.85718 28.0761,-16.58594 l 2.7305,-3.36719 z m -3.0684,27.45313 c 0,3.38574 0.136,6.70285 0.3848,9.9375 l 8.1484,-9.9375 z"
      />
      <g
        transform="matrix(1.562219,0,0,1.562219,495.14388,-543.8206)"
        style={{ fill: '#4c5544', fillOpacity: 1 }}
      >
        <path
          d="m 491.06,795.505 h 5.386 v -1.153 h -5.386 z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1807.1817,1775.2174)"
        />
        <path
          d="m 491.06,786.034 h 5.386 v -1.153 h -5.386 z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1807.1817,1775.2174)"
        />
        <path
          d="m 491.06,790.769 h 5.386 v -1.153 h -5.386 z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1807.1817,1775.2174)"
        />
        <path
          d="M 0,0 H -10.669 V -1.153 H 5.386 V 0 Z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1031.753,714.54405)"
        />
        <path
          d="M 0,0 H -10.669 V -1.156 H 5.386 V 0 Z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1031.753,727.17271)"
        />
        <path
          d="M 0,0 H -10.669 V -1.153 H 5.386 V 0 Z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1031.753,720.85845)"
        />
        <path
          d="M 0,0 V 3.6 H 5.751 C 6.745,3.6 7.539,2.803 7.539,1.812 7.539,0.829 6.745,0.026 5.751,0.026 Z m 8.693,1.812 c 0,1.624 -1.316,2.941 -2.942,2.941 H -1.147 V -5.907 H 0 v 4.754 l 4.351,0.024 3.735,-4.745 h 1.471 l -3.745,4.753 c 1.598,0.027 2.881,1.335 2.881,2.933"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1101.211,720.88165)"
        />
        <path
          d="m 0,0 c -2.539,0 -4.609,2.07 -4.609,4.609 0,2.539 2.07,4.607 4.609,4.607 2.539,0 4.607,-2.068 4.607,-4.607 C 4.607,2.07 2.539,0 0,0 m 0,10.369 c -3.173,0 -5.762,-2.58 -5.762,-5.76 0,-3.173 2.589,-5.762 5.762,-5.762 3.18,0 5.762,2.589 5.762,5.762 0,3.18 -2.582,5.76 -5.762,5.76"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-917.7706,727.70978)"
        />
        <path
          d="M 0,0 -8.316,6.821 H -9.472 V -3.806 h 1.156 v 9.13 L 0,-1.497 V -3.806 H 1.155 V 6.821 H 0 Z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-969.39422,723.63911)"
        />
        <path
          d="M 0,0 H 6.83 L 0.009,-8.318 H 0 v -1.156 h 10.625 v 1.156 H 1.497 L 8.318,0 h 2.307 V 1.153 H 0 Z"
          style={{ fill: '#4c5544', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
          transform="matrix(1.3333333,0,0,-1.3333332,-1208.9812,716.08178)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ZerenoLogoHalloween;
