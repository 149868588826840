import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './spider-animation.json';

type SpiderAnimationProps = Omit<LottieComponentProps, 'animationData'>;

const SpiderAnimation = (props: SpiderAnimationProps) => {
  return <Lottie animationData={animationData} {...props} />;
};

export default SpiderAnimation;
