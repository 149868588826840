import SvgIcon from '@mui/material/SvgIcon';

export default (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="36.135475mm"
        height="14.348726mm"
        viewBox="0 0 36.135475 14.348726"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs id="defs1" />
        <path
          id="path46"
          d="m 19.746329,3.4324732 h -0.72894 l -0.92842,1.132221 h 1.65736 v 0.364297 h -0.72749 c -0.77776,0 -1.51456,0.348565 -2.00774,0.950072 l -0.14933,0.181902 h 2.88456 v 0.364298 h -2.92013 -0.26319 -0.1513 -0.0226 v -0.01844 -0.343894 -0.002 h 0.002 l 0.92839,-1.131976 h -0.92989 v -0.364297 c 0.77776,0 1.51459,-0.34881 2.00776,-0.950072 l 0.14929,-0.182148 v 0 h -2.15705 v -0.364297 h 3.35719 z m 3.28191,-0.465081 c -0.73218,-1.415399 -2.10874,-2.48075801 -3.66239,-2.83448506 -0.97507,-0.222216 -2.00911,-0.161746 -2.95756,0.15117605 -0.80956,0.2672 -2.01855,0.708437 -2.60017,1.34509601 -0.895427,0.980308 -1.434887,3.275476 -0.946017,4.5338 0.241607,0.62191 0.781387,1.071013 1.302717,1.487422 0.39259,0.313414 0.79071,0.627072 1.2172,0.890586 0.21324,0.131756 0.43356,0.250976 0.66384,0.351514 1.16708,0.5088363 2.53432,0.4886793 3.72493,0.03761 1.1906,-0.451316 2.20514,-1.312649 2.93057,-2.359081 0.41356,-0.596591 0.74555,-1.281921 0.75635,-2.007811 0.008,-0.556523 -0.17372,-1.10174 -0.42947,-1.595827"
        />
        <g id="g3" transform="matrix(0.04333941,0,0,0.04333941,-19.16201,-20.129709)">
          <path id="path36" d="m 599.87557,758.81121 h 20.02133 v -4.28 h -20.02133 v 4.28" />
          <path id="path37" d="m 599.87557,794.05121 h 20.02133 v -4.2933 h -20.02133 v 4.2933" />
          <path id="path38" d="m 599.87557,776.42461 h 20.02133 v -4.28 h -20.02133 v 4.28" />
          <path
            id="path39"
            d="m 936.52356,754.53121 h -39.69067 v 4.28 h 59.712 v -4.28 h -0.12267 -19.89866"
          />
          <path
            id="path40"
            d="m 936.52356,789.75791 h -39.69067 v 4.2933 h 59.712 v -4.2933 h -0.12267 -19.89866"
          />
          <path
            id="path41"
            d="m 936.52356,772.14461 h -39.69067 v 4.28 h 59.712 v -4.28 h -0.12267 -19.89866"
          />
          <path
            id="path42"
            d="m 742.77423,772.19791 v -13.3733 h 21.396 c 3.664,0 6.64667,2.9733 6.64667,6.64 0,3.6666 -2.98267,6.6533 -6.65734,6.6533 z m 32.33067,-6.7333 c 0,-6.0267 -4.90534,-10.9334 -10.93467,-10.9334 h -25.684 v 39.64 h 4.288 v -17.68 l 16.184,-0.067 13.892,17.6266 c 1.87333,0 3.61333,0 5.46133,0 l -13.92266,-17.6666 c 5.928,-0.1067 10.716,-4.96 10.716,-10.92"
          />
          <path
            id="path43"
            d="m 1254.4942,791.25121 c -9.4466,0 -17.1333,-7.68 -17.1333,-17.1333 0,-9.44 7.6867,-17.1333 17.1333,-17.1333 9.448,0 17.1347,7.6933 17.1347,17.1333 0,9.4533 -7.6867,17.1333 -17.1347,17.1333 z m 0,-38.56 c -11.812,0 -21.4213,9.6134 -21.4213,21.4267 0,11.8133 9.6093,21.4267 21.4213,21.4267 11.8133,0 21.4227,-9.6134 21.4227,-21.4267 0,-11.8133 -9.6094,-21.4267 -21.4227,-21.4267"
          />
          <path
            id="path44"
            d="m 1110.4849,779.91791 -30.9413,-25.3733 v -0.013 h -4.288 v 0.2667 1.7733 3.1067 34.3733 h 4.288 v -33.96 l 30.9413,25.3734 v 8.5866 h 4.2893 v -39.52 h -4.2893 v 25.3867"
          />
          <path
            id="path45"
            d="m 442.13824,758.81121 h 25.39066 l -25.37333,30.9467 h -0.0173 v 0.027 4.04 0.2266 h 0.26533 1.78133 3.09867 34.37333 v -4.2933 h -33.95466 l 25.37333,-30.9467 h 8.58133 v -4.28 h -39.51866 v 4.28"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
