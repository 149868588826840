import { Fragment, useEffect } from 'react';
import { Element } from 'react-scroll';

import { useStore } from '@state/store';
import { MenuSectionTitle } from '../styles';
import { Alert, Box, Divider, Grid, Typography } from '@mui/material';
import { COLOR } from '@utils/colors';
import { PublicSection } from '../../../types';
import { useInView } from 'react-intersection-observer';
import { useScroll } from '@state/scroll/scroll';
import { useIntl } from 'react-intl';
import { DAYPART_HOURS, PRODUCT_VIEW } from '@state/store/constants';
import ProductCardV2 from '@components/ProductCardV2';
import ProductCard from '@components/ProductCard';

type SectionProps = {
  menuSection: PublicSection;
  sectionIndex: number;
  onShowDetails(id: string): void;
};

const Section = ({ menuSection, sectionIndex, onShowDetails }: SectionProps) => {
  const { id, name, showSectionName, productCategory, inCurrentDaypart, daypart } = menuSection;
  const { language, productListView } = useStore();
  const { setInViewSectionId } = useScroll();
  const { formatMessage } = useIntl();

  const { ref, inView } = useInView({
    threshold: 0.1,
    rootMargin: '100px',
  });

  useEffect(() => {
    setInViewSectionId({ id, inView, index: sectionIndex });
  }, [id, inView, sectionIndex, setInViewSectionId]);

  return (
    <Box id={id} ref={ref}>
      <Element name={id}>
        <MenuSectionTitle variant="h5">{name[language]}</MenuSectionTitle>
        {!inCurrentDaypart && !!DAYPART_HOURS(daypart) && (
          <Box sx={{ padding: '15px' }}>
            <Alert severity="error">
              {formatMessage({ id: 'unavailableDaypart' }, { time: DAYPART_HOURS(daypart) })}
            </Alert>
          </Box>
        )}
        <Grid
          container
          spacing={productListView === PRODUCT_VIEW.LIST ? 1 : 2}
          maxWidth="md"
          mb="50px"
          sx={{
            opacity: inCurrentDaypart ? 1 : 0.5,
            paddingX: '15px',
          }}
        >
          {productCategory.map(({ id, name, products }, index) => (
            <Fragment key={id}>
              {showSectionName && (
                <Grid key={`${id}-title`} item xs={12}>
                  <Box sx={{ paddingX: '0px', paddingTop: index === 0 ? '0px' : '20px' }}>
                    <Typography sx={{ fontWeight: 500, color: COLOR.Zereno }}>
                      {name[language]}
                    </Typography>
                    <Divider />
                  </Box>
                </Grid>
              )}
              {products.map(product => {
                const {
                  id: productId,
                  name,
                  description,
                  priceVariant,
                  thumbUrl,
                  imageUrl,
                  isVegetarian,
                } = product;
                return productListView === PRODUCT_VIEW.LIST ? (
                  <Grid key={productId} item xs={12}>
                    <ProductCardV2
                      key={productId}
                      id={productId}
                      name={name[language]}
                      description={description[language]}
                      priceVariant={priceVariant}
                      imageUrl={thumbUrl || imageUrl}
                      onClick={() => onShowDetails(productId)}
                      isVegetarian={isVegetarian}
                    />
                  </Grid>
                ) : (
                  <Grid key={productId} item xs={6} md={2}>
                    <ProductCard
                      id={productId}
                      name={name[language]}
                      description={description[language]}
                      priceVariant={priceVariant}
                      imageUrl={thumbUrl || imageUrl}
                      onClick={() => onShowDetails(productId)}
                      isVegetarian={isVegetarian}
                    />
                  </Grid>
                );
              })}
            </Fragment>
          ))}
        </Grid>
      </Element>
    </Box>
  );
};

export default Section;
