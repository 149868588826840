export const apiUrl = process.env.REACT_APP_MENU_BACKEND_URL || 'https://zerenocafe.com/api';

export const SCREEN_MAX_WIDTH = 1200;
export const SCREEN_WIDTH_MD = 700;

export const LANGUAGE = {
  EN: 'EN',
  ES: 'ES',
} as const;

export const PRODUCT_VIEW = {
  LIST: 'LIST',
  GRID: 'GRID',
} as const;

export const PRICE_VARIANT = {
  REGULAR: 'regular',
  TEMPERATURE: 'temperature',
  COFFEE: 'coffee',
  PRODUCT_VARIANT: 'product-variant',
} as const;

export const DAYPART_HOURS = (daypart: string) =>
  ({
    BREAKFAST: '2 p.m.',
  }[daypart] || undefined);
