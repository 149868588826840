import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
import { COLOR } from '@utils/colors';

export const ProductCardContainer = styled(Paper)({
  cursor: 'pointer',
  borderRadius: '10px',
  boxShadow: '0px 1px 1px #d1d1d1',
  overflow: 'hidden',
  height: '100%',
});

export const ProductImage = styled(Box)(({ src }: { src: string }) => ({
  background: `url(${src})`,
  backgroundColor: 'white',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100%',
  paddingTop: '100%',
}));

export const ProductDescriptionContainer = styled(Box)({
  padding: '10px',
});

export const ProductTitle = styled(Typography)({

  fontSize: '18px',
  fontWeight: 500,
});

export const IconTitleWrapper = styled(Box)({
  display: 'flex',
  borderBottomWidth: 0.5,
  borderBottomStyle: 'dashed',
  borderBottomColor: COLOR.DarkGray,
  marginBottom: '5px',
  paddingBottom: '5px',
});

export const ProductDescription = styled(Typography)({
  fontSize: '12px',
  color: COLOR.Gray,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '5px',
});

export const ProductPrice = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
});
