import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import App from './App';
import { StoreProvider } from '@state/store';
import { ScrollProvider } from '@state/scroll';
import { OverlayProvider } from '@state/overlay';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StoreProvider>
        <ScrollProvider>
          <OverlayProvider>
            <Router>
              <App />
            </Router>
          </OverlayProvider>
        </ScrollProvider>
      </StoreProvider>
    </ThemeProvider>
    // </React.StrictMode>
  );
} else {
  console.error("No se encontró el elemento con el ID 'root'");
}
