import { Fragment } from 'react';

import { Divider } from '@mui/material';
import { useStore } from '@state/store';
import { PublicProduct } from '../../types/index';
import {
  ExtraContainer,
  ExtrasTitle,
  ListContainer,
  NameContainer,
  PriceContainer,
  Row,
} from './styles';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';

type ExtrasProps = {
  product: PublicProduct;
};

const Extras = ({ product }: ExtrasProps) => {
  const { language } = useStore();
  const { formatMessage } = useIntl();

  const extrasData = useMemo(() => {
    return product.productExtras;
  }, [product.productExtras]);

  if (!extrasData.some(({ extra: { items } }) => items?.length > 0)) {
    return null;
  }

  return (
    <>
      <ExtrasTitle>{formatMessage({ id: 'extrasTitle' })}</ExtrasTitle>
      <ExtraContainer>
        <ListContainer>
          {extrasData.map(({ extra: { id, items } }, index) => (
            <Fragment key={id}>
              {items.map(({ id: itemId, name, price }) => (
                <Fragment key={itemId}>
                  <Row>
                    <NameContainer>{name[language]}</NameContainer>
                    <PriceContainer>${price}</PriceContainer>
                  </Row>
                  {index < extrasData.length - 1 && <Divider />}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </ListContainer>
      </ExtraContainer>
    </>
  );
};

export default Extras;
