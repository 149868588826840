import { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { useStore } from '@state/store';
import EnFlagIcon from '@icons/en-flag';
import EsFlagIcon from '@icons/es-flag';
import { NavBarButton } from './styles';
import { LANGUAGE } from '@state/store/constants';
import { Language } from '../../types';

const LanguageSelector = () => {
  const { language, setLanguage } = useStore();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang: Language) => {
    setLanguage(lang);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <NavBarButton onClick={handleClick}>
        <Typography
          variant="subtitle2"
          color="black"
          sx={{ fontSize: '16px', textTransform: 'capitalize' }}
        >
          {language.toLowerCase()}
        </Typography>
      </NavBarButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleChangeLanguage(LANGUAGE.ES)}
              disabled={language === LANGUAGE.ES}
            >
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <EsFlagIcon />
              </ListItemIcon>
              <ListItemText primary="Español" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleChangeLanguage(LANGUAGE.EN)}
              disabled={language === LANGUAGE.EN}
            >
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <EnFlagIcon />
              </ListItemIcon>
              <ListItemText primary="English" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default LanguageSelector;
