import { createContext, useContext, useReducer, ReactNode, useCallback } from 'react';

type State = {
  showBatAnimation: boolean;
  origin: { x: number; y: number };
};

type OverlayProps = State & {
  setShowBatAnimation(show: boolean, x?: number, y?: number): void;
};

const initialState: OverlayProps = {
  showBatAnimation: false,
  origin: { x: 0, y: 0 },
  setShowBatAnimation: () => {},
};

export const OverlayContext = createContext<OverlayProps>(initialState);

export const useOverlay = () => useContext(OverlayContext);

enum ACTION {
  SET_SHOW_BAT_ANIMATION,
  SET_ORIGIN_ANIMATION,
}

type Action =
  | {
      type: ACTION.SET_SHOW_BAT_ANIMATION;
      payload: boolean;
    }
  | {
      type: ACTION.SET_ORIGIN_ANIMATION;
      payload: { x: number; y: number };
    };

const overlayReducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_SHOW_BAT_ANIMATION:
      return {
        ...state,
        showBatAnimation: payload,
      };
    case ACTION.SET_ORIGIN_ANIMATION:
      return {
        ...state,
        origin: payload,
      };
    default:
      return state;
  }
};

export const OverlayProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(overlayReducer, initialState);

  const setShowBatAnimation = useCallback((show: boolean, x?: number, y?: number) => {
    if (x && y) {
      dispatch({ type: ACTION.SET_ORIGIN_ANIMATION, payload: { x, y } });
    }
    dispatch({ type: ACTION.SET_SHOW_BAT_ANIMATION, payload: show });
  }, []);

  const OverlayValues: OverlayProps = {
    ...state,
    setShowBatAnimation,
  };

  return <OverlayContext.Provider value={OverlayValues}>{children}</OverlayContext.Provider>;
};

export default OverlayContext.Consumer;
