import { useNavigate, useLocation } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReorderIcon from '@mui/icons-material/Reorder';
import GridViewIcon from '@mui/icons-material/GridView';

import LanguageSelector from '@components/LanguageSelector';
import { NavBarButton } from '@components/LanguageSelector/styles';
import { HeaderContainer, PageTitle } from './styles';
import { Box, useMediaQuery } from '@mui/material';
import { CONTAINER_MAX_WIDTH_MOBILE } from 'theme';
import { useStore } from '@state/store';
import { PRODUCT_VIEW } from '@state/store/constants';
import { THEMES } from '../../types';

type HeaderProps = {
  pageTitle?: string;
  backRoute?: string;
};

const Header = ({ pageTitle, backRoute }: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productListView, setProductListView, theme } = useStore();
  const isSmallScreen = useMediaQuery(`(max-width:${CONTAINER_MAX_WIDTH_MOBILE}px)`);

  // Verifica si la URL actual coincide con la ruta específica del menú
  const isMenuPage = location.pathname.startsWith('/menu/');

  const handleNavigate = () => {
    backRoute && navigate(backRoute);
  };

  const handleToggleView = () => {
    if (productListView === PRODUCT_VIEW.GRID) {
      setProductListView(PRODUCT_VIEW.LIST);
    } else {
      setProductListView(PRODUCT_VIEW.GRID);
    }
  };

  return (
    <HeaderContainer>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: `${isSmallScreen ? 100 : 150}px`,
          background: `url(${theme === THEMES.HALLOWEEN ? '/header-halloween.png' : '/header.png'}) no-repeat top right`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '90% 100%',
          zIndex: 0,
        }}
      />
      {!!backRoute && (
        <Box sx={{ marginLeft: '20px' }}>
          <NavBarButton onClick={handleNavigate}>
            <ArrowBackIcon />
          </NavBarButton>
        </Box>
      )}
      {pageTitle ? <PageTitle>{pageTitle}</PageTitle> : <div />}
      <Box sx={{ marginRight: '20px' }}>
        <LanguageSelector />
      </Box>
      {isMenuPage && (
        <Box sx={{ marginRight: '20px', position: 'absolute', right: '50px', zIndex: 1 }}>
          <NavBarButton onClick={handleToggleView}>
            {productListView === PRODUCT_VIEW.GRID ? <ReorderIcon /> : <GridViewIcon />}
          </NavBarButton>
        </Box>
      )}
    </HeaderContainer>
  );
};

export default Header;
