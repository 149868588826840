import {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';

type InViewSection = {
  id: string;
  inView: boolean;
  index: number;
};

type State = {
  selectedSectionId?: string;
  inViewSectionIds: InViewSection[];
};

type ScrollProps = State & {
  setInViewSectionId(payload: InViewSection): void;
};

const initialState: ScrollProps = {
  selectedSectionId: undefined,
  inViewSectionIds: [],
  setInViewSectionId: () => [],
};

export const ScrollContext = createContext<ScrollProps>(initialState);

export const useScroll = () => useContext(ScrollContext);

enum ACTION {
  // Sets the status of a section whether is visible or not
  SET_IN_VIEW_SECTION_ID,
  // Set ONE selected section ID
  SET_SELECTED_SECTION_ID,
}

type Action =
  | {
      type: ACTION.SET_IN_VIEW_SECTION_ID;
      payload: InViewSection;
    }
  | {
      type: ACTION.SET_SELECTED_SECTION_ID;
      payload: string | undefined;
    };

const scrollReducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.SET_IN_VIEW_SECTION_ID:
      return {
        ...state,
        inViewSectionIds: [
          ...state.inViewSectionIds.filter(({ id }) => id !== payload.id),
          { ...payload },
        ],
      };
    case ACTION.SET_SELECTED_SECTION_ID:
      return { ...state, selectedSectionId: payload };
    default:
      return state;
  }
};

export const ScrollProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(scrollReducer, initialState);

  const setInViewSectionId = useCallback((payload: InViewSection) => {
    dispatch({ type: ACTION.SET_IN_VIEW_SECTION_ID, payload });
  }, []);

  const setSelectedSectionId = useCallback((id: string) => {
    dispatch({ type: ACTION.SET_SELECTED_SECTION_ID, payload: id });
  }, []);

  const getActiveSectionId = useCallback((): string | undefined => {
    const activeSections = state.inViewSectionIds.filter(({ inView }) => inView);

    if (!activeSections.length) {
      return undefined;
    }

    if (activeSections.length === 1) {
      return activeSections[0].id;
    }

    return activeSections.reduce((min, cur) => (cur.index < min.index ? cur : min)).id;
  }, [state.inViewSectionIds]);

  useEffect(() => {
    const activeSection = getActiveSectionId();
    if (activeSection && activeSection !== state.selectedSectionId) {
      setSelectedSectionId(activeSection);
    }
  }, [getActiveSectionId, setSelectedSectionId, state.selectedSectionId]);

  const ScrollValues: ScrollProps = {
    ...state,
    setInViewSectionId,
  };

  return <ScrollContext.Provider value={ScrollValues}>{children}</ScrollContext.Provider>;
};

export default ScrollContext.Consumer;
