import { useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from '@state/store';
import ProductDetails from '@components/ProductDetails';
import Section from './Section';
import { PublicProduct } from '../../types';
import { useSearchParams } from 'react-router-dom';
import { CONTAINER_MAX_WIDTH_MOBILE } from 'theme';
import { Alert, Box, useMediaQuery } from '@mui/material';
import ProductDetailsWeb from '@components/ProductDetailsWeb';

export type ProductWithDaypart = PublicProduct & {
  daypart: string;
  inCurrentDaypart: boolean;
};

const SELECTED_PRODUCT_KEY = 'product-id';

export const setTimeOfDate = (originalDate: Date) => {
  const date = new Date();
  date.setHours(originalDate.getHours());
  date.setMinutes(originalDate.getMinutes());
  date.setSeconds(originalDate.getSeconds());
  date.setMilliseconds(originalDate.getMilliseconds());
  return date;
};

// Verify after one minute for message time
const REFRESH_MESSAGE_TIME = 1000 * 60;

const ProductList = () => {
  const isSmallScreen = useMediaQuery(`(max-width:${CONTAINER_MAX_WIDTH_MOBILE}px)`);
  const [searchParams, setSearchParams] = useSearchParams();
  const { menuContent, getProductById, language } = useStore();
  const [selectedProduct, setSelectedProduct] = useState<ProductWithDaypart | undefined>();
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());

  const handleSetSearchParam = (value?: string) => {
    if (value) {
      searchParams.set(SELECTED_PRODUCT_KEY, value);
    } else {
      searchParams.delete(SELECTED_PRODUCT_KEY);
    }
    setSearchParams(searchParams);
  };

  const handleSelectProduct = useCallback(
    (id?: string) => {
      if (id) {
        const product = getProductById(id);
        setSelectedProduct(product);
      } else {
        setSelectedProduct(undefined);
      }
    },
    [getProductById]
  );

  const messageInfo = useMemo(() => {
    if (!menuContent.messages || !(menuContent.messages?.length > 0)) {
      return null;
    }

    for (const message of menuContent.messages) {
      const { visibleFrom, visibleTo } = message;
      const visibleFromDate = new Date(visibleFrom);

      const visibleToDate = new Date(visibleTo);

      const timeToStartShowingDate = setTimeOfDate(visibleFromDate);
      const timeToStopShowingDate = setTimeOfDate(visibleToDate);

      if (
        currentDateTime.getTime() >= timeToStartShowingDate.getTime() &&
        currentDateTime.getTime() < timeToStopShowingDate.getTime()
      ) {
        return message[language];
      }
    }
  }, [currentDateTime, language, menuContent.messages]);

  useEffect(() => {
    setInterval(() => {
      setCurrentDateTime(new Date());
    }, REFRESH_MESSAGE_TIME);
  }, []);

  useEffect(() => {
    const selectedProductId = searchParams?.get(SELECTED_PRODUCT_KEY) ?? undefined;
    handleSelectProduct(selectedProductId);
  }, [handleSelectProduct, searchParams]);

  return (
    <>
      <div style={{ overflow: 'auto', paddingTop: '30px' }}>
        {messageInfo && (
          <Box sx={{ display: 'flex' }}>
            <Alert severity="error" sx={{ width: '100%', marginBottom: '20px' }}>
              {messageInfo}
            </Alert>
          </Box>
        )}
        {menuContent?.sections?.map((menuSection, index) => (
          <Section
            key={menuSection.id}
            menuSection={menuSection}
            sectionIndex={index}
            onShowDetails={id => handleSetSearchParam(id)}
          />
        ))}
      </div>
      {isSmallScreen ? (
        <ProductDetails selectedProduct={selectedProduct} onClose={() => handleSetSearchParam()} />
      ) : (
        <ProductDetailsWeb
          selectedProduct={selectedProduct}
          onClose={() => handleSetSearchParam()}
        />
      )}
    </>
  );
};

export default ProductList;
