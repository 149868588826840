import { ReactElement, Ref, forwardRef } from 'react';

import { Alert, Box, Dialog, Divider, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

import { useStore } from '@state/store';
import {
  BluredImage,
  BodyContainer,
  DialogContent,
  FixedNavBarContainer,
  HeaderContainer,
  Price,
  PricesContainer,
  ProductDescription,
  ProductName,
} from './styles';
import { NavBarButton } from '@components/LanguageSelector/styles';
import { DAYPART_HOURS, PRICE_VARIANT } from '@state/store/constants';
import Extras from '@components/Extras';
import TemperaturePriceVariant from '@components/PriceVariants/TemperaturePriceVariant';
import CoffeeTypePriceVariant from '@components/PriceVariants/CoffeeTypePriceVariant';
import { convertMlToOz } from '@utils/functions';
import ProductVariantPriceVariant from '@components/PriceVariants/ProductVariantPriceVariant';
import { ProductWithDaypart } from '@components/ProductList/product-list';
import { useIntl } from 'react-intl';
import { COLOR } from '@utils/colors';

type ProductDetailsProps = {
  selectedProduct?: ProductWithDaypart;
  onClose(): void;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductDetails = ({ selectedProduct, onClose }: ProductDetailsProps) => {
  const { language } = useStore();
  const { formatMessage } = useIntl();

  const handleClose = () => {
    onClose();
  };

  if (!selectedProduct) {
    return null;
  }

  const { name, description, priceVariant, thumbUrl, imageUrl, volume, daypart, inCurrentDaypart } =
    selectedProduct || {};
  return (
    <Dialog
      open={!!selectedProduct}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          overflowY: 'auto',
          overflowX: 'hidden',
          background: COLOR.backgroundMenu,
        },
      }}
    >
      <DialogContent>
        <FixedNavBarContainer>
          <NavBarButton onClick={onClose}>
            <CloseIcon width="10px" height="10px" />
          </NavBarButton>
        </FixedNavBarContainer>
        <BluredImage src={imageUrl || thumbUrl} />
        <HeaderContainer src={imageUrl || thumbUrl} />

        <BodyContainer>
          <ProductName>{name[language]}</ProductName>

          {!inCurrentDaypart && (
            <Box sx={{ padding: '5px' }}>
              <Alert severity="error">
                {formatMessage(
                  { id: 'unavailableDaypartProduct' },
                  { time: DAYPART_HOURS(daypart) }
                )}
              </Alert>
            </Box>
          )}
          <PricesContainer>
            {priceVariant?.type === PRICE_VARIANT.REGULAR && (
              <Price>${priceVariant.prices[0].price}</Price>
            )}

            {priceVariant?.type === PRICE_VARIANT.TEMPERATURE && (
              <TemperaturePriceVariant priceVariant={priceVariant} />
            )}

            {priceVariant?.type === PRICE_VARIANT.COFFEE && (
              <CoffeeTypePriceVariant priceVariant={priceVariant} />
            )}

            {priceVariant?.type === PRICE_VARIANT.PRODUCT_VARIANT && (
              <ProductVariantPriceVariant priceVariant={priceVariant} />
            )}
          </PricesContainer>

          <Divider sx={{ width: '100%' }} />

          <ProductDescription variant="body1">{description[language]}</ProductDescription>
          {!!volume && (
            <ProductDescription variant="body1" style={{ fontWeight: 500, marginBottom: '30px' }}>
              {volume} ml / {convertMlToOz(volume)} oz
            </ProductDescription>
          )}

          <Extras product={selectedProduct} />
        </BodyContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDetails;
