export const COLOR = {
  LightGray: '#e0e0e0',
  Gray: '#999999',
  DarkGray: '#525252',
  Zereno: '#4c5544',
  ZerenoLight: '#87967b',
  backgroundMenu: '#eeeae3',
  ColdBlue: '#1a04b0',
  HotRed: '#8a0a01',
  Golden: '#B8860B',
};
