import { Routes, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import Home from '@pages/home';
import { ROUTES } from '@utils/routes';
import { useStore } from '@state/store';
import { LANGUAGE } from '@state/store/constants';

import './App.css';
import messages_en from '@i18n/en.json';
import messages_es from '@i18n/es.json';
import { Language, THEMES } from './types';
import MenuPage from '@pages/menu/menu';
import HalloweenOverlay from '@components/HalloweenOverlay';

const messages = (lang: Language) => {
  switch (lang) {
    case LANGUAGE.EN:
      return messages_en;
    case LANGUAGE.ES:
      return messages_es;
    default:
      return messages_en;
  }
};

const App = () => {
  const { language, theme } = useStore();

  return (
    <IntlProvider locale={language} messages={messages(language)}>
      {theme === THEMES.HALLOWEEN && <HalloweenOverlay />}
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.MENU(':menuId')} element={<MenuPage />} />
      </Routes>
    </IntlProvider>
  );
};

export default App;
