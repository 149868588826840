import SpiderAnimation from '@components/Animations/spider-animation';
import { SpiderContainer, SpiderwebRightContainer } from './styles';
import BatAnimationClick from './BatAnimationClick';
import SpiderwebRight from 'svg/SpiderwebRight';
import { Box } from '@mui/material';

const HalloweenOverlay = () => {
  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <SpiderContainer>
        <SpiderAnimation />
      </SpiderContainer>
      <BatAnimationClick />
      <SpiderwebRightContainer>
        <SpiderwebRight
          sx={{ position: 'absolute', right: 0, bottom: '-3px', height: '90px', width: '90px' }}
        />
      </SpiderwebRightContainer>
    </Box>
  );
};

export default HalloweenOverlay;
