import BatAnimation from '@components/Animations/bat-animation';
import { BatAnimationItem, BatContainer } from './styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOverlay } from '@state/overlay';

const NUM_OF_BATS = 50;

const BatAnimationClick = () => {
  const { origin, showBatAnimation, setShowBatAnimation } = useOverlay();
  const [animationEndCount, setAnimationEndCount] = useState(0);

  const handleAnimationEnd = useCallback(() => {
    setAnimationEndCount(prevState => prevState + 1);
  }, []);

  useEffect(() => {
    if (animationEndCount === NUM_OF_BATS) {
      setShowBatAnimation(false);
      setAnimationEndCount(0);
    }
  }, [animationEndCount, setShowBatAnimation]);

  const Animation = useMemo(() => {
    return Array.from({ length: NUM_OF_BATS }).map((_, index) => {
      const duration = Math.random() * 1.5;

      return (
        <BatAnimationItem key={index} duration={duration} onAnimationEnd={handleAnimationEnd}>
          <BatAnimation style={{ height: '200px' }} />
        </BatAnimationItem>
      );
    });
  }, [handleAnimationEnd]);

  if (!showBatAnimation) {
    return null;
  }

  return (
    <BatContainer top={origin.y - 50} left={origin.x - 50}>
      {Animation}
    </BatContainer>
  );
};

export default BatAnimationClick;
