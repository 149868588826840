import { useIntl } from 'react-intl';
import { Price, PriceName, PriceRow } from './styles';
import { PriceVariant } from '../../../types';

type CoffeeTypePriceVariantProps = {
  priceVariant: PriceVariant;
};

const CoffeeTypePriceVariant = ({ priceVariant }: CoffeeTypePriceVariantProps) => {
  const { formatMessage } = useIntl();

  const { prices } = priceVariant;

  return (
    <>
      <PriceRow>
        <PriceName>{formatMessage({ id: 'regular' })}</PriceName>
        <Price>${prices.find(({ id }) => id === 'regular')?.price}</Price>
      </PriceRow>
      <PriceRow>
        <PriceName>{formatMessage({ id: 'premium' })}</PriceName>
        <Price>${prices.find(({ id }) => id === 'premium')?.price}</Price>
      </PriceRow>
    </>
  );
};

export default CoffeeTypePriceVariant;
