import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import ZerenoLogoIcon from '@icons/zereno-logo';
import ZerenoLogoHalloweenIcon from '@icons/zereno-logo-halloween';
import { COLOR } from '@utils/colors';

export const LogoContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
});

export const ZerenoLogo = styled(ZerenoLogoIcon)({
  width: '80%',
  maxWidth: '300px',
  height: 'auto',
  fill: COLOR.Zereno,
});

export const ZerenoHalloweenLogo = styled(ZerenoLogoHalloweenIcon)({
  width: '80%',
  maxWidth: '300px',
  height: 'auto',
  fill: COLOR.Zereno,
});

export const ButtonsContainer = styled(Box)({
  width: '100%',
  maxWidth: '400px',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
});

export const BannersContainer = styled(Box)({
  width: '100%',
  padding: '10px 30px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  maxWidth: '400px',
});

export const MenuButton = styled(Button)({
  marginTop: '15px',
  borderRadius: '20px',
  padding: '10px',
  borderColor: COLOR.LightGray,
  color: COLOR.Zereno,
  background: 'rgba(255,255,255,.5)',
  fontSize: '18px',

  '&:focus': {
    background: 'rgba(255,255,255,.5)',
    borderColor: COLOR.LightGray, // Elimina el borde al enfocarse
  },
});
