import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Template from '@components/Template';
import ProductList from '@components/ProductList';
import { ROUTES } from '@utils/routes';
import { useStore } from '@state/store';
import { Box, CircularProgress } from '@mui/material';
import { COLOR } from '@utils/colors';
import ZerenoLogo from '@icons/zereno-logo';

const MenuPage = () => {
  const { menuId } = useParams();
  const { loadMenuContent, menuContent, language } = useStore();
  const [loadingMenuContent, setLoadingMenuContent] = useState(false);

  const loadMenu = useCallback(
    async (menu: string) => {
      setLoadingMenuContent(true);
      await loadMenuContent(menu);
      setLoadingMenuContent(false);
    },
    [loadMenuContent]
  );

  useEffect(() => {
    if (!menuId) {
      return;
    }
    loadMenu(menuId);
  }, [loadMenu, loadMenuContent, menuId]);

  return (
    <Template backRoute={ROUTES.HOME} pageTitle={menuContent?.name?.[language] ?? ''} isMenuPage>
      {loadingMenuContent ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '50px',
            width: '100%',
            height: '300px',
            position: 'relative',
          }}
        >
          <CircularProgress size={65} thickness={2} sx={{ color: COLOR.Zereno }} />
          <ZerenoLogo
            sx={{
              width: '180px',
              height: '80px',
              position: 'absolute',
              top: 6,
              fill: COLOR.Zereno,
            }}
          />
        </Box>
      ) : (
      <ProductList />
      )}
    </Template>
  );
};

export default MenuPage;
