import { getMenuIcon } from '@pages/home';
import { useMemo } from 'react';
import { ButtonContainer, ButtonLabel } from './styles';
import { ROUTES } from '@utils/routes';
import { useNavigate } from 'react-router-dom';

type NavBarProps = {
  id: string;
  name: string;
  icon: string;
  selected?: boolean;
};

const NavBarIconButton = ({ id, name, icon, selected = false }: NavBarProps) => {
  const navigate = useNavigate();
  const IconComponent = useMemo(() => getMenuIcon(icon), [icon]);

  const handleClick = () => {
    if (selected) {
      return;
    }
    const menuRoute = id === 'home' ? ROUTES.HOME : ROUTES.MENU(id);
    navigate(menuRoute);
  };

  if (!IconComponent) {
    return null;
  }

  return (
    <ButtonContainer selected={selected} onClick={handleClick}>
      <IconComponent sx={{ fontSize: '18px' }} />
      <ButtonLabel selected={selected}>{name}</ButtonLabel>
    </ButtonContainer>
  );
};

export default NavBarIconButton;
