import { useNavigate } from 'react-router-dom';

import CoffeeIcon from '@mui/icons-material/Coffee';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StorefrontIcon from '@mui/icons-material/Storefront';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import HomeIcon from '@mui/icons-material/Home';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import Template from '@components/Template';
import { ROUTES } from '@utils/routes';

import {
  BannersContainer,
  ButtonsContainer,
  LogoContainer,
  MenuButton,
  ZerenoHalloweenLogo,
  ZerenoLogo,
} from './styles';
import { useStore } from '@state/store';
import { MENU_ICONS, THEMES } from '../../types/index';
import { Box, Button, Dialog, DialogContent, IconButton, Link, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import ReviewDialog from '@components/ReviewDialog';
import { useIntl } from 'react-intl';
import { useOverlay } from '@state/overlay';
import { setTimeOfDate } from '@components/ProductList/product-list';

export const getMenuIcon = (icon: string) =>
  ({
    [MENU_ICONS.COFFEE_CUP]: CoffeeIcon,
    [MENU_ICONS.FORKS]: RestaurantIcon,
    [MENU_ICONS.BAKERY]: BakeryDiningIcon,
    [MENU_ICONS.SHOP]: StorefrontIcon,
    [MENU_ICONS.HOME]: HomeIcon,
  }[icon] || null);

const getInstantiatedIcon = (icon: string) => {
  const Icon = getMenuIcon(icon);

  return Icon && <Icon />;
};

type StartBlockProps = {
  rateValue: number;
  onPressStar(rate: number): void;
};

export const StartBlock = ({ rateValue, onPressStar }: StartBlockProps) => {
  const handleClickOnStar = (rate: number) => {
    if (rate === 5) {
      window.open('https://g.page/r/CS8O-fVuNxEDEAE/review', '_blank');
    } else {
      onPressStar(rate);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {[1, 2, 3, 4, 5].map(number => (
        <IconButton key={number} size="small" onClick={() => handleClickOnStar(number)}>
          {rateValue >= number ? (
            <StarIcon
              sx={{
                width: '30px',
                height: '30px',
                ...(rateValue >= number && { color: '#F4B400' }),
              }}
            />
          ) : (
            <StarBorderIcon
              sx={{
                width: '30px',
                height: '30px',
              }}
            />
          )}
        </IconButton>
      ))}
    </Box>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { menus, banners, language, theme } = useStore();
  const [showPopup, setShowPopup] = useState(false);
  const [rateValue, setRateValue] = useState(0);
  const { setShowBatAnimation } = useOverlay();

  const handleNavigate = (route: string, e: React.MouseEvent) => {
    const { clientX, clientY } = e;
    setShowBatAnimation(true, clientX, clientY);
    navigate(route);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleReviewCloseDialog = () => {
    setRateValue(0);
  };

  const popup = useMemo(() => {
    const popUps = banners.filter(({ isPopUp }) => isPopUp);
    if (!popUps.length) {
      // No pop-up to show
      return undefined;
    }

    // First priority - Popups in a time range
    const timeRangePopUps = popUps.filter(
      ({ visibleFrom, visibleTo }) => !!visibleFrom && !!visibleTo
    );
    for (const popup of timeRangePopUps) {
      const { visibleFrom, visibleTo } = popup;
      if (!visibleFrom || !visibleTo) {
        continue;
      }
      const currentDateTime = new Date();
      const visibleFromDate = new Date(visibleFrom);
      const visibleToDate = new Date(visibleTo);

      const timeToStartShowingDate = setTimeOfDate(visibleFromDate);
      const timeToStopShowingDate = setTimeOfDate(visibleToDate);

      if (
        currentDateTime.getTime() >= timeToStartShowingDate.getTime() &&
        currentDateTime.getTime() < timeToStopShowingDate.getTime()
      ) {
        return popup;
      }
    }

    // If not banner on range time, show fixed popup (first in the list)
    const fixedPopUps = popUps.filter(({ visibleFrom, visibleTo }) => !visibleFrom && !visibleTo);
    if (fixedPopUps.length > 0) {
      return fixedPopUps[0];
    } else {
      return undefined;
    }
  }, [banners]);

  useEffect(() => {
    if (popup && !!popup.image[language]) {
      const hasSeenBanner = sessionStorage.getItem('hasSeenBanner');
      if (hasSeenBanner) {
        return;
      }
      setShowPopup(true);
      sessionStorage.setItem('hasSeenBanner', 'true');
    }
  }, [language, popup]);

  return (
    <Template>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <LogoContainer>
          {theme === THEMES.HALLOWEEN ? <ZerenoHalloweenLogo /> : <ZerenoLogo />}
        </LogoContainer>

        <ButtonsContainer>
          {menus.map(({ id, name, icon }) => (
            <MenuButton
              key={id}
              variant="outlined"
              startIcon={getInstantiatedIcon(icon)}
              onClick={e => handleNavigate(ROUTES.MENU(id), e)}
            >
              {name[language]}
            </MenuButton>
          ))}
        </ButtonsContainer>

        <BannersContainer sx={{ flexGrow: 1 }}>
          {banners
            .filter(({ isPopUp, image }) => !isPopUp && !!image[language])
            .map(({ id, linkTo, image }) => (
              <Link
                key={id}
                href={linkTo}
                sx={{
                  borderRadius: '20px',
                  overflow: 'hidden',
                  marginBottom: '20px',
                  lineHeight: 0,
                }}
              >
                <img src={image[language]} alt="popup" width="100%" />
              </Link>
            ))}
        </BannersContainer>

        <Typography sx={{ fontSize: '18px', opacity: 0.4 }}>
          <em>{formatMessage({ id: 'likeToKnowYourOpinion' })}</em>
        </Typography>

        <BannersContainer mb="10px">
          <StartBlock
            rateValue={rateValue}
            onPressStar={(number: number) => setRateValue(number)}
          />
        </BannersContainer>
      </Box>

      {rateValue > 0 && (
        <ReviewDialog
          rateValue={rateValue}
          onClose={handleReviewCloseDialog}
          setRateValue={setRateValue}
        />
      )}

      <Dialog open={showPopup} onClose={handleClosePopup}>
        <DialogContent sx={{ position: 'relative', padding: '5px' }}>
          {!!popup?.linkTo ? (
            <Link href={popup.linkTo} sx={{ userSelect: 'none' }}>
              <img src={popup.image[language]} alt="popup" width="100%" />
            </Link>
          ) : (
            <img src={popup?.image[language]} alt="popup" width="100%" />
          )}
          <Box
            sx={{
              position: 'absolute',
              left: 0,

              bottom: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              background: 'white',
            }}
          >
            <Button onClick={handleClosePopup} color="secondary" size="large">
              {formatMessage({ id: 'close' })}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Template>
  );
};

export default HomePage;
