import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { COLOR } from '@utils/colors';

export const MenuSectionTitle = styled(Typography)({
  color: COLOR.Zereno,
  marginLeft: '15px',
  marginBottom: '15px',
  fontSize: '28px',
  fontFamily: 'Glastone-Regular',
  textTransform: 'uppercase',
});
