import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './bat-animation.json';

type BatAnimationProps = Omit<LottieComponentProps, 'animationData'>;

const BatAnimation = (props: BatAnimationProps) => {
  return <Lottie animationData={animationData} {...props} />;
};

export default BatAnimation;
