import { useStore } from '@state/store';
import { NavBarContainer } from './styles';
import NavIconButton from './nav-bar-icon-button';
import { useMemo } from 'react';
import { MENU_ICONS } from '../../types';

const NavBar = () => {
  const { language, navigationButtons, menuContent } = useStore();

  const currentMenuId = useMemo(() => {
    return menuContent.id;
  }, [menuContent.id]);

  if (navigationButtons.length < 1) {
    return null;
  }

  return (
    <NavBarContainer>
      <NavIconButton id="home" name="Home" icon={MENU_ICONS.HOME} />
      {navigationButtons.map(({ id, name, icon }) => (
        <NavIconButton id={id} name={name[language]} icon={icon} selected={currentMenuId === id} />
      ))}
    </NavBarContainer>
  );
};

export default NavBar;
