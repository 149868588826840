import { Box } from '@mui/material';
import { COLOR } from '@utils/colors';

type ScrollLinkProps = {
  selected: boolean;
  children: React.ReactNode;
  onClick(): void;
};

const ScrollLink = ({ selected, onClick, children }: ScrollLinkProps) => {
  return (
    <Box onClick={onClick}>
      <span
        style={{
          display: 'block',
          color: selected ? COLOR.ZerenoLight : COLOR.Zereno,
          height: '26px',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          fontSize: 16,
          marginTop: 0,
          marginBottom: 0,
          marginLeft: '15px',
          marginRight: '35px',
          borderBottomWidth: '4px',
          borderBottomStyle: 'solid',
          borderBottomColor: selected ? COLOR.ZerenoLight : 'transparent',
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        {children}
      </span>
    </Box>
  );
};

export default ScrollLink;
