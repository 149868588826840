import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Price = styled(Typography)({
  fontSize: '22px',
  fontWeight: 600,
  letterSpacing: '.5px',
  textAlign: 'right',
});

export const PriceName = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '.5px',
  marginRight: '10px',
});

export const PriceRow = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
});
