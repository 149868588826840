import { useIntl } from 'react-intl';
import { Price, PriceName, PriceRow } from './styles';
import { COLOR } from '@utils/colors';
import { PriceVariant } from '../../../types';

type TemperaturePriceVariantProps = {
  priceVariant: PriceVariant;
};

const TemperaturePriceVariant = ({ priceVariant }: TemperaturePriceVariantProps) => {
  const { formatMessage } = useIntl();

  const { prices } = priceVariant;

  return (
    <>
      <PriceRow color={COLOR.HotRed}>
        <PriceName>{formatMessage({ id: 'hot' })}</PriceName>
        <Price>${prices.find(({ id }) => id === 'hot')?.price}</Price>
      </PriceRow>
      <PriceRow color={COLOR.ColdBlue}>
        <PriceName>{formatMessage({ id: 'cold' })}</PriceName>
        <Price>${prices.find(({ id }) => id === 'cold')?.price}</Price>
      </PriceRow>
    </>
  );
};

export default TemperaturePriceVariant;
