import { PRICE_VARIANT } from '@state/store/constants';
import { PriceVariant } from '../../types';
import {
  ProductCardContainer,
  ProductTitle,
  ProductDescription,
  ProductPrice,
  ProductDescriptionContainer,
  IconTitleWrapper,
  ProductImageContainer,
} from './styles';
import { Box, Typography } from '@mui/material';
import LeaveIcon from '@icons/leave';

import ColdIcon from '@icons/cold';
import HotIcon from '@icons/hot';
import StarIcon from '@icons/star';
import { useIntl } from 'react-intl';
import { useOverlay } from '@state/overlay';

type ProductCardProps = {
  id: string;
  name: string;
  description: string;
  priceVariant?: PriceVariant;
  imageUrl?: string;
  isVegetarian: boolean;
  onClick(): void;
};

export const defaultProductImage = 'https://zerenocafe.com/images/product-images/default.png';

const getMinPrice = (priceVariant: PriceVariant): number => {
  return priceVariant.prices.reduce((min, { price }) => {
    return price < min ? price : min;
  }, priceVariant.prices[0].price);
};

const ProductCard = ({
  name,
  description,
  priceVariant,
  imageUrl,
  isVegetarian,
  onClick,
}: ProductCardProps) => {
  const { formatMessage } = useIntl();
  const { setShowBatAnimation } = useOverlay();

  const hanldeProductClick = (e: React.MouseEvent) => {
    const { clientX, clientY } = e;
    setShowBatAnimation(true, clientX, clientY);
    onClick();
  };

  return (
    <ProductCardContainer onClick={hanldeProductClick}>
      <ProductImageContainer>
        <img
          src={imageUrl || defaultProductImage}
          alt={name}
          width="90px"
          height="90px"
          style={{ borderRadius: '5px' }}
        />
      </ProductImageContainer>
      <ProductDescriptionContainer>
        <IconTitleWrapper>
          {isVegetarian && (
            <LeaveIcon sx={{ width: '15px', marginTop: '0px', marginRight: '5px' }} />
          )}
          <ProductTitle variant="body2">{name}</ProductTitle>
        </IconTitleWrapper>
        <ProductDescription>{description}</ProductDescription>

        {/* Regular Price */}
        {priceVariant?.type === PRICE_VARIANT.REGULAR && (
          <ProductPrice>${priceVariant.prices[0].price}</ProductPrice>
        )}

        {/* Price by Temperature */}
        {priceVariant?.type === PRICE_VARIANT.TEMPERATURE && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex' }}>
              <HotIcon sx={{ width: '10px', marginX: '5px' }} />
              <ProductPrice>
                ${priceVariant.prices.find(({ id }) => id === 'hot')?.price}
              </ProductPrice>
            </Box>
            <Typography sx={{ marginLeft: '5px' }}>/</Typography>
            <Box sx={{ display: 'flex' }}>
              <ColdIcon sx={{ width: '10px', marginX: '5px' }} />
              <ProductPrice>
                ${priceVariant.prices.find(({ id }) => id === 'cold')?.price}
              </ProductPrice>
            </Box>
          </Box>
        )}

        {/* Price by coffee type */}
        {priceVariant?.type === PRICE_VARIANT.COFFEE && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex' }}>
              <ProductPrice>
                ${priceVariant.prices.find(({ id }) => id === 'regular')?.price}
              </ProductPrice>
            </Box>
            <Typography sx={{ marginLeft: '5px' }}>/</Typography>
            <Box sx={{ display: 'flex' }}>
              <StarIcon sx={{ width: '16px', marginX: '5px' }} />
              <ProductPrice>
                ${priceVariant.prices.find(({ id }) => id === 'premium')?.price}
              </ProductPrice>
            </Box>
          </Box>
        )}

        {/* Price by Product Variant */}
        {priceVariant?.type === PRICE_VARIANT.PRODUCT_VARIANT && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ marginRight: '5px' }}>{formatMessage({ id: 'from' })}</Typography>
              <ProductPrice>${getMinPrice(priceVariant)}</ProductPrice>
            </Box>
          </Box>
        )}
      </ProductDescriptionContainer>
    </ProductCardContainer>
  );
};

export default ProductCard;
