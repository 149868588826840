import { Box, styled, keyframes } from '@mui/material';
export const SpiderContainer = styled(Box)({
  position: 'absolute',
  left: 0,
  width: '170px',
  zIndex: 2,
  pointerEvents: 'none',
});

export const SpiderwebRightContainer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
  zIndex: 2,
  pointerEvents: 'none',
  overflow: 'hidden'
});

export const BatContainer = styled(Box)({
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 9999,
});

const createFlyAwayAnimation = () => keyframes`
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(${Math.random() * 1000 - 500}px, ${Math.random() * 1000 - 500}px) scale(${
  Math.random() * 0.5 + 0.5
});
  }
`;

export const BatAnimationItem = styled(Box)(({ duration }: { duration: number }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100px', // Ajusta el tamaño de los murciélagos
  height: '100px',
  animation: `${createFlyAwayAnimation()} ${duration}s ease-out forwards`,
  animationIterationCount: 1,
}));
