import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { StartBlock } from '@pages/home';
import { useStore } from '@state/store';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Snackbar from '@mui/material/Snackbar';

type ReviewDialogProps = {
  rateValue: number;
  setRateValue: React.Dispatch<React.SetStateAction<number>>;
  onClose(): void;
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ReviewDialog = ({ rateValue, onClose, setRateValue }: ReviewDialogProps) => {
  const { formatMessage } = useIntl();
  const [comments, setComments] = useState('');
  const [requestResponse, setRequestResponse] = useState(false);
  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sentMessage, setSendMessage] = useState('');
  const { sendReview } = useStore();

  const isEmailValid = emailRegex.test(email);
  const isOpen = rateValue > 0;

  const isFormValid =
    !requestResponse || (requestResponse && !!comments && !!email && isEmailValid);

  const handleSubmitComment = async () => {
    setIsLoading(true);
    try {
      await sendReview(rateValue, comments, email);
      setSendMessage(formatMessage({ id: 'weReceiveYouReview' }));
    } catch (error) {
      setSendMessage(formatMessage({ id: 'errorSending' }));
    } finally {
      setIsLoading(false);
    }
  };

  if (!!sentMessage) {
    return (
      <Snackbar open autoHideDuration={6000} onClose={onClose} message={sentMessage}>
        <Alert onClose={onClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          {sentMessage}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <DialogContent sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '18px' }}>
            <strong>{formatMessage({ id: 'howWasYourVisit' })}</strong>
          </Typography>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={onClose}
            sx={{ background: 'white' }}
          >
            <CloseIcon sx={{ width: '25px', height: '25px' }} />
          </IconButton>
        </Box>
        <Typography
          sx={{ fontSize: '16px', textAlign: 'center', marginTop: '25px', marginBottom: '15px' }}
        >
          <em>{formatMessage({ id: 'yourFeedback' })}</em>
        </Typography>
        <StartBlock rateValue={rateValue} onPressStar={(number: number) => setRateValue(number)} />

        <TextField
          fullWidth
          multiline
          rows={5}
          label={formatMessage({ id: 'comments' })}
          sx={{ marginTop: '20px' }}
          value={comments}
          onChange={({ target }) => setComments(target.value)}
        />

        <FormControlLabel
          sx={{ mt: '20px' }}
          control={
            <Switch
              id="active"
              name="justified"
              checked={requestResponse}
              onChange={({ target }) => {
                setRequestResponse(target.checked);
                if (!target.checked) {
                  setEmail('');
                }
              }}
            />
          }
          label={formatMessage({ id: 'wantAnswer' })}
        />
        {requestResponse && (
          <TextField
            fullWidth
            label="Email"
            required
            sx={{ marginTop: '20px' }}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            onBlur={() => setEmailTouched(true)}
            error={emailTouched && !isEmailValid}
            helperText={emailTouched && !isEmailValid && formatMessage({ id: 'invalidEmail' })}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'space-between', paddingBottom: '20px', paddingX: '20px' }}
      >
        <Button onClick={onClose} color="secondary">
          {formatMessage({ id: 'close' })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmitComment}
          color="primary"
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? formatMessage({ id: 'sending' }) : formatMessage({ id: 'send' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewDialog;
