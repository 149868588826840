import { Price, PriceName, PriceRow } from './styles';
import { PriceVariant } from '../../../types';
import { useStore } from '@state/store';

type ProductVariantPriceVariantProps = {
  priceVariant: PriceVariant;
};

const ProductVariantPriceVariant = ({ priceVariant }: ProductVariantPriceVariantProps) => {
  const { language } = useStore();
  const { prices } = priceVariant;

  return (
    <>
      {prices.map(({ id, name, price }) => (
        <PriceRow key={id}>
          {!!name && <PriceName>{name[language]}</PriceName>}
          <Price>${price}</Price>
        </PriceRow>
      ))}
    </>
  );
};

export default ProductVariantPriceVariant;
