import { LANGUAGE } from '@state/store/constants';

export const isValidLanguage = (language: string): language is keyof typeof LANGUAGE => {
  return language === LANGUAGE.EN || language === LANGUAGE.ES;
};

export const convertMlToOz = (volumeMl: number): string => {
  return (volumeMl / 29.7535).toFixed(2);
};

export const fetchData = async <T>(url: string, options?: RequestInit): Promise<T> => {
  try {
    const response = await fetch(url, {
      ...options,
      method: options?.method || 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data: T = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};
