import { styled } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { COLOR } from '@utils/colors';
import { CONTAINER_MAX_WIDTH_MOBILE, HEADER_CONTAINER_MAX_WIDTH_MOBILE } from 'theme';

export const HeaderContainer = styled(Box)(() => {
  const isSmallScreen = useMediaQuery(`(max-width:${CONTAINER_MAX_WIDTH_MOBILE}px)`);

  return {
    position: 'relative',
    minHeight: '30px',
    height: `${isSmallScreen ? 30 : 55}px`,
    padding: '10px 0',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderBottomRightRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderBottomColor: COLOR.LightGray,
    width: '100%',
    maxWidth: `${HEADER_CONTAINER_MAX_WIDTH_MOBILE}px`,
  };
});

export const PageTitle = styled(Typography)({
  color: COLOR.LightGray,
  fontWeight: 500,
  fontSize: '18px',
  width: '100%',
  paddingLeft: '20px',
  zIndex: 1,
  textAlign: 'center',
});
