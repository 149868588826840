import { SvgIcon } from '@mui/material';
import React from 'react';

const SpiderwebRight = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 39.56659 36.947746">
    <g transform="matrix(0.26458333,0,0,0.26458334,94.275725,-316.17342)">
      <g
        transform="matrix(-1.0069567,0,0,-1.0069567,133.43303,1526.106)"
        style={{ opacity: 1, fill: '#1a1a1a' }}
      >
        <g transform="translate(-147.49313,-270.53779)" style={{ fill: '#1a1a1a' }}>
          <path
            d="m 487.5413,465.20001 c 0.18134,-1.01867 0.36134,-2.03733 0.54134,-3.056 0.24133,-1.092 -1.452,-1.316 -1.5,-0.204 -1.01467,23.04133 -0.68667,46.20266 -0.856,69.26266 -0.0853,11.54267 -0.12267,23.0864 -0.14667,34.63067 -0.024,10.81453 -0.82667,22.03747 0.71867,32.75467 0.15066,1.04266 1.94533,1.04266 2.096,0 1.544,-10.7172 0.74133,-21.94014 0.71866,-32.75467 -0.0253,-11.54427 -0.0627,-23.088 -0.14666,-34.63067 -0.16934,-23.06 0.15866,-46.22133 -0.856,-69.26266 -0.0493,-1.112 -1.74267,-0.888 -1.50134,0.204 0.18,1.01867 0.36134,2.03733 0.54134,3.056 0.0333,0.25067 0.35733,0.25067 0.39066,0 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 486.74664,462.68801 c 11.10933,2.03733 23.17733,1.032 34.44133,1.14667 12.17733,0.124 24.35733,0.13333 36.53466,0.144 25.01334,0.02 50.248,0.97866 75.21867,-0.544 1.22533,-0.0747 1.22533,-1.82667 0,-1.90134 -24.26,-1.48 -48.768,-0.56666 -73.06933,-0.54666 -12.17867,0.0107 -24.356,0.0693 -36.53467,0.13066 -11.93466,0.0613 -24.824,-0.996 -36.59066,1.16134 -0.204,0.0373 -0.204,0.372 0,0.40933 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 487.59197,462.02001 c 5.53067,20.58666 11.932,40.96266 18.36667,61.28266 6.436,20.32387 12.71733,40.776 20.39066,60.67334 0.47067,1.2188 2.38933,0.75213 1.99733,-0.55054 -6.13866,-20.4228 -13.38399,-40.51506 -20.07199,-60.76146 -6.69467,-20.264 -13.072,-40.644 -19.96267,-60.84267 -0.15333,-0.452 -0.84533,-0.27066 -0.72,0.19867 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 489.97997,462.44668 c 14.98,19.72266 30.84,38.99866 48.17333,56.70533 16.464,16.81626 33.88,32.43866 48.33467,51.1188 0.63333,0.81826 1.98666,-0.2468 1.42666,-1.10147 -12.62266,-19.236 -30.50133,-34.29587 -46.728,-50.356 -18.076,-17.888 -33.81866,-37.76 -50.32666,-57.04666 -0.41067,-0.48 -1.26533,0.172 -0.88,0.68 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 489.2093,463.64668 c 9.43867,4.27599 20.636,5.87999 30.62934,8.59866 11.59466,3.156 23.15733,6.43867 34.68666,9.82933 10.852,3.192 21.66133,6.52934 32.44133,9.95467 11.328,3.6 22.70534,8.50267 34.30934,11.03733 0.98666,0.21467 1.71866,-1.232 0.72,-1.70666 -9.54,-4.53067 -20.304,-7.14 -30.35867,-10.324 -11.45733,-3.62667 -22.96667,-7.08134 -34.512,-10.41334 -11.54667,-3.33333 -23.13867,-6.50133 -34.776,-9.50533 -10.62,-2.74267 -22.13733,-6.992 -33.12266,-7.53333 -0.032,-10e-4 -0.048,0.0493 -0.0173,0.0627 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 487.93597,513.64534 c 3.15067,-5.40533 9.34933,-6.984 13.71333,-1.91733 0.50934,0.592 1.36134,0.192 1.59334,-0.42 1.43866,-3.804 3.67733,-6.64 7.00133,-8.97734 2.736,-1.92533 5.912,-1.47333 8.58933,-2.88133 1.37867,-0.724 1.85333,-0.91333 2.31333,-2.584 0.96,-3.48933 0.756,-6.38667 2.54,-9.74267 1.54934,-2.91466 4.108,-5.156 6.83867,-6.91066 2.544,-1.636 5.664,-1.40667 8.136,-2.85467 0.44,-0.25733 0.74267,-1.04667 0.72,-1.512 -0.24,-5.12533 1.90667,-9.30533 6.888,-11.30933 1.10533,-0.444 0.64667,-2.24933 -0.50133,-1.81733 -4.9,1.84533 -7.72534,5.70133 -8.364,10.86666 -0.42267,3.412 -3.624,2.99067 -6.54667,4.284 -2.76667,1.224 -5.072,3.40267 -7.01067,5.664 -1.72133,2.008 -2.81066,4.41067 -3.67066,6.88133 -0.332,0.95067 -0.56933,2.12 -0.65467,3.12267 -0.31466,3.65733 -2.95066,4.26533 -5.84933,5.05467 -5.876,1.60133 -10.28533,6.66133 -12.268,12.208 0.53067,-0.14 1.06133,-0.28 1.59333,-0.42 -4.84266,-5.33467 -13.636,-4.48667 -15.68266,3.004 -0.10134,0.37333 0.42666,0.596 0.62133,0.26133 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 486.85597,544.50001 c 5.72933,-7.0588 15.36,-9.62814 23.58133,-5.4292 0.68267,0.348 1.49334,0.0521 1.72134,-0.7 3.72666,-12.2668 17.46799,-19.23747 29.56399,-15.08947 0.81067,0.27867 1.46534,-0.43867 1.54,-1.17333 1.84534,-18.172 18.648,-32.25067 36.79467,-31.272 0.768,0.0413 1.384,-0.80134 1.15867,-1.52134 -2.6,-8.32266 -1.60134,-16.65866 2.928,-24.12133 0.79733,-1.31333 -1.26,-2.508 -2.052,-1.19866 -4.81334,7.95866 -5.944,17.06666 -3.19467,25.95866 0.38667,-0.50667 0.77333,-1.01467 1.16,-1.52133 -19.56133,-1.112 -37.24933,14.27866 -39.228,33.676 0.51333,-0.39067 1.02667,-0.78134 1.54,-1.172 -13.35733,-4.588 -28.5,3.28533 -32.47066,16.81186 0.57466,-0.23426 1.148,-0.4676 1.72133,-0.70093 -9.00267,-4.49093 -20.22667,-1.87347 -25.644,6.7744 -0.33333,0.53173 0.47467,1.17867 0.88,0.67867 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 487.02397,574.56667 c 6.85067,-11.18386 21.28267,-16.412 33.21733,-9.97866 0.612,0.3308 1.2,0.10106 1.596,-0.41867 9.932,-13.0548 25.664,-20.136 42.012,-19 0.56933,0.04 0.96267,-0.40427 1.15333,-0.8772 8.624,-21.50413 27.16,-37.9148 49.504,-43.95213 0.81867,-0.22134 1.34534,-1.296 0.83067,-2.04134 -6.99467,-10.12933 -4.54667,-24.22533 5.31867,-31.54 1.396,-1.03466 0.092,-3.39599 -1.388,-2.37599 -11.29067,7.78666 -14.108,24.08266 -6.332,35.31866 0.27733,-0.68 0.55466,-1.36133 0.832,-2.04133 -19.556,5.30666 -36.01334,18.17333 -46.24,35.624 -1.34134,2.28946 -2.476,4.69053 -3.55334,7.11186 -1.22533,2.752 -2.18933,2.21094 -5.05733,2.22134 -5.04,0.0213 -10.05867,1.1068 -14.83067,2.64946 -9.74266,3.14947 -18.17066,9.5828 -24.26399,17.72547 0.53199,-0.13907 1.06399,-0.27853 1.59599,-0.41867 -12.36266,-6.53746 -29.70799,-1.9428 -35.40666,11.40107 -0.288,0.6776 0.60667,1.2536 1.012,0.59213 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
          <path
            d="m 528.72397,583.62547 c 13.924,-15.66146 34.528,-24.088 55.29066,-18.07853 0.76534,0.2208 1.46534,-0.38707 1.49867,-1.1428 0.57333,-12.99373 4.13467,-25.07347 10.90933,-36.2028 3.324,-5.45867 7.52267,-10.32933 12.14134,-14.72267 4.34266,-4.132 9.77466,-6.70933 14.17733,-10.63866 0.676,-0.604 -0.036,-1.7 -0.86,-1.476 -5.692,1.55733 -10.90267,6.33466 -15.20933,10.22266 -4.908,4.432 -9.02,9.7 -12.56267,15.26134 -7.052,11.07333 -11.03733,24.42386 -10.964,37.55613 0.49867,-0.38013 0.99867,-0.76147 1.49867,-1.1416 -20.348,-5.224 -44.756,1.64787 -56.58667,19.84947 -0.26133,0.40253 0.34667,0.8744 0.66667,0.51346 v 0"
            style={{
              fill: '#1a1a1a',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 0.133333,
            }}
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default SpiderwebRight;
