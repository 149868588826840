import SvgIcon from '@mui/material/SvgIcon';
import { COLOR } from '@utils/colors';

export default (props: any) => {
  return (
    <SvgIcon {...props}  fill={COLOR.HotRed}>
      <svg
        width="4.1433196mm"
        height="5.3287849mm"
        viewBox="0 0 4.1433196 5.3287849"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs id="defs1">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath149">
            <path
              d="M 0,841.89 H 1040.322 V 0 H 0 Z"
              transform="translate(-663.25712,-551.67911)"
              id="path149"
            />
          </clipPath>
        </defs>
        <g id="layer-MC0" transform="matrix(0.26458332,0,0,0.26458335,-232.10251,-99.316719)">
          <path
            id="path148"
            d="m 0,0 c 0.079,-0.362 0.308,-0.643 0.627,-0.77 0.33,-0.128 0.7,-0.079 1.019,0.141 1.014,0.7 1.695,1.707 2.081,3.077 L 3.882,2.993 4.033,2.823 C 4.597,2.189 4.975,1.501 5.188,0.717 5.534,-0.554 5.348,-1.881 4.663,-3.02 3.979,-4.157 2.896,-4.943 1.614,-5.232 1.255,-5.313 0.895,-5.354 0.537,-5.354 c -0.936,0 -1.852,0.276 -2.655,0.808 -1.106,0.733 -1.843,1.856 -2.075,3.159 -0.243,1.362 0.069,2.635 0.928,3.787 0.203,0.271 0.409,0.541 0.614,0.81 0.262,0.344 0.524,0.688 0.781,1.036 0.568,0.771 0.995,1.595 1.269,2.449 0.015,0.043 0.036,0.084 0.064,0.135 L -0.411,7.076 -0.29,6.923 C 0.268,6.224 0.569,5.4 0.633,4.405 0.716,3.098 0.392,1.883 0.064,0.867 -0.031,0.575 -0.055,0.251 0,0 M -0.298,8.441 C -0.554,8.651 -0.797,8.727 -0.986,8.659 -1.168,8.59 -1.298,8.38 -1.351,8.067 -1.539,6.953 -1.98,5.908 -2.664,4.964 -2.926,4.602 -3.199,4.247 -3.471,3.892 -3.632,3.681 -3.794,3.47 -3.953,3.258 -4.771,2.17 -5.2,1.152 -5.302,0.055 c -0.275,-2.959 1.606,-5.608 4.472,-6.298 0.206,-0.049 0.415,-0.087 0.624,-0.124 0.099,-0.018 0.198,-0.036 0.296,-0.055 h 0.915 c 0.105,0.025 0.236,0.048 0.368,0.071 0.269,0.047 0.547,0.097 0.805,0.18 2.393,0.772 3.794,2.375 4.162,4.768 C 6.692,0.883 5.824,2.881 3.759,4.538 3.642,4.632 3.367,4.641 3.178,4.594 3.113,4.578 2.915,4.53 2.902,4.089 2.877,3.303 2.709,2.567 2.405,1.904 2.135,1.318 1.798,0.856 1.402,0.531 L 1.144,0.318 1.2,0.648 c 0.05,0.3 0.109,0.601 0.168,0.903 0.129,0.663 0.263,1.347 0.312,2.028 0.147,2.027 -0.519,3.663 -1.978,4.862"
            transform="matrix(1.3333333,0,0,-1.3333333,884.3428,386.94787)"
            clipPath="url(#clipPath149)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
