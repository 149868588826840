import { createTheme } from '@mui/material/styles';

export const HEADER_CONTAINER_MAX_WIDTH_MOBILE = 1200;
export const CONTAINER_MAX_WIDTH_MOBILE = 900;

const theme = createTheme({
  typography: {
    fontFamily:
      'Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
});

export default theme;
