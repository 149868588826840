import SvgIcon from '@mui/material/SvgIcon';
import { COLOR } from '@utils/colors';

const LeaveIcon = (props: any) => {
  return (
    <SvgIcon {...props} stroke={COLOR.Golden}>
      <svg
        width="3.2667253mm"
        height="3.2538228mm"
        viewBox="0 0 3.2667253 3.2538228"
        version="1.1"
        id="svg1"
        // inkscape:version="1.3.2 (091e20e, 2023-11-25)"
        // sodipodi:docname="Menu dieno.svg"
        // xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        // xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:svg="http://www.w3.org/2000/svg"
      >
        <defs id="defs1">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath554-3">
            <path
              d="M 0,841.89 H 1040.322 V 0 H 0 Z"
              transform="translate(-377.62993,-584.01181)"
              id="path554-9"
            />
          </clipPath>
        </defs>
        <g
          id="layer-MC0"
          // inkscape:groupmode="layer"
          // inkscape:label="Capa 1"
          transform="matrix(0.26458332,0,0,0.26458335,-505.02689,-93.612144)"
          style={{ display: 'inline' }}
        >
          <path
            id="path439"
            d="M 0,0 H 1040.322 V 841.89 H 0 Z"
            style={{ fill: 'none' }}
            transform="matrix(1.3333333,0,0,-1.3333333,1407.0933,1122.52)"
          />
          <path
            id="path553"
            d="m 0,0 c 0.776,-1.835 0.564,-4.232 1.815,-6.633 0.666,-1.279 2.087,-2.125 3.528,-2.185 0.988,-0.041 1.988,0.33 2.716,0.989 0.486,-0.567 0.745,-1.357 0.745,-1.357 l 0.456,0.344 c -0.263,0.482 -0.55,0.943 -0.85,1.378 -0.048,0.07 -0.096,0.139 -0.145,0.208 -2.042,2.869 -4.621,4.621 -4.621,4.621 0,0 2.815,-0.991 5.072,-4.396 0.054,0.091 0.103,0.184 0.147,0.279 0.395,0.847 0.425,1.842 0.158,2.737 C 8.755,-3.12 8.206,-2.322 7.52,-1.689 5.911,-0.201 3.829,0.168 0,0"
            style={{
              display: 'inline',
              fill: '#4c5544',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            transform="matrix(1.3333333,0,0,-1.3333333,1908.7633,353.85956)"
            clipPath="url(#clipPath554-3)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default LeaveIcon;
